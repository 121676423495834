/* You can add global styles to this file, and also import other style files */
@use "../src/app/global.scss" as global;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@300;400;500;600;700;900&display=swap");

body,
.modal-content {
  background-color: #f7f8fa;
  overflow: auto !important;
  font-family: var(--font-family);
  font-size: 16px;
}

:root {
  --primary-color: #2e57ee;
  --accent-color: rgb(255, 120, 0);
  --accent-color-light: rgb(255, 152, 62);
  --primary-color-1: #6e3ef8;
  --blog-heading-color: #222222;
  --blog-text-color: #333333;
  --grey-color: #999ec58c;
  --light-grey: #999ec525;
  --dark-grey: #343436;
  --hover-bg-color: #0e79de;
  --section-bg-color: #edf4fb;
  --white-color: #ffffff;
  --black-color: #000000;

  --white-text-color: #ffffff;
  --black-text-color: #000000;
  --dark-text-color: #2c2c51;

  --font-family: "Poppins", sans-serif;
  // rgb's
  --primary-color-rgb: 61, 100, 244;
  --box-shadow-color: #111111;
  --accent-color-light-rgb: 253, 154, 88;
}

a {
  text-decoration: none;
}

.renoWebsiteBtn {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #3d64f4;
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  display: inline-block;
  padding: 16px 42px;
  border-radius: 45px;
  border: 0px;
  transition: all 0.2s linear;
}

.renoWebsiteBtn:hover {
  color: #3d64f4;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.superAdminAddButton {
  background: var(--primary-color);
  border: none;
  margin-top: 10px;
  color: var(--white-text-color);
  height: 40px;
  width: 30%;
  font-family: var(--text-family);
  border-radius: 20px;
}
