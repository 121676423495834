.input-container-validation {
  position: relative;

  // display: inline-block;
  .invalid-input {
    border: 1px solid #ff0000 !important;
    /* Add a red border to the input */
    background-color: #fff5f5 !important;
    /* Change the background color to a light red */

    // color: #ff0000; /* Change the text color to red */
    // padding-right: 30px; /* Adjust the padding to control the spacing between the icon and input text */
  }

  .invalid-input-icon {
    position: absolute;
    // transform: translateY(-50%);
    right: 20px;
    /* Adjust the right position as needed */
    top: 95%;
    /* Vertically center the icon */
    color: #ff0000;
    /* Change the text color to red */
  }

  .big-validation-icon {
    font-size: 1.5rem;
  }

  .validation-span {
    bottom: 4px;
    position: relative;
  }

  .valid-input:focus {
    border: 2px solid green;
    /* Add a green border to the input when it's focused and valid */
    background-color: #e7f7e7;
    /* Change the background color to a light green */
    // color: #ffffff; /* Change the text color to green */
  }
}
.swal2-confirm.confirm-btn {
  color: #dc3545 !important; /* Light Red Text */
  padding: .625em 2.1em !important;
}

.swal2-cancel.cancel-btn {
  color: rgb(95, 94, 94) !important; /* Grey Text */
  padding: .625em 2.1em !important;
}

/*********btn download****/

.download_btn {
  font-weight: 500;
   padding: 17px 28px;
   background-image: linear-gradient(40deg, #5e2ced 0%, #5316fa 100%);
   border: 2px solid white;
   border-radius: 5px;
   color: rgb(250, 250, 250);
   display: inline-block;
   transition: all 0.3s ease;
}

.download_btn:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
   border: 2px solid white;
   color: white;
}

/******************************qr code ***********/
.app-download-icon-container .app-download-qr{
  width: 185px; height: 185px; border-radius: 12px; border:2px solid var(--accent-color);
  background-color: white;
  display: flex;
  align-items: center;justify-content: center;
}

.separator {
  line-height: 0.5;
  text-align: center;
  padding: 50px 0 50px 0;
}

.separator span {
  display: inline-block;
  position: relative;
}

.separator span:before,
.separator span:after {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  position: absolute;
  width: 300px;
  top: 5px;
}

.separator span:before {
  right: 100%;
  margin-right: 15px;
}

.separator span:after {
  left: 100%;
  margin-left: 15px;
}

.separator span i.bi-square-fill:before {
  font-size: 6px;
  padding: 0;
  margin: 0;
  float: none;
  color: rgb(35, 2, 109);
}

@media screen and (max-width: 767.5px) {

  .separator span:before,
  .separator span:after {
    content: "";
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    position: absolute;
    width: 150px !important;
    top: 5px;
  }
}

body::-webkit-scrollbar {
  width: 0px;
}



.heading_style h1,
.heading_style h2,
.heading_style h3,
.heading_style h4,
.heading_style h5,
.heading_style h6 {
  color: #4b505e;
  font-weight: 700;
}
.heading_style .h_title {
  font-size: 24px;
  color: #677294;
  font-weight: 300;
}
.heading_style h1 {
  font-size: 50px;
}
.heading_style h2 {
  font-size: 44px;
}
.heading_style h3 {
  font-size: 36px;
}
.heading_style h4 {
  font-size: 30px;
}
.heading_style h5 {
  font-size: 24px;
}
.heading_style h6 {
  font-size: 18px;
}
.heading_style.medium h1,
.heading_style.medium h2,
.heading_style.medium h3,
.heading_style.medium h4,
.heading_style.medium h5,
.heading_style.medium h6 {
  font-weight: 500;
}
.heading_style.medium .h_title {
  font-weight: 300;
}
.heading_style.regular .h_title {
  font-weight: 300;
}
.heading_style.regular h1,
.heading_style.regular h2,
.heading_style.regular h3,
.heading_style.regular h4,
.heading_style.regular h5,
.heading_style.regular h6 {
  font-weight: 400;
}


.blockquote {
    border-left: 2px solid #4b505e;
    padding-left: 30px;
  }
  .blockquote p {
    font-size: 18px;
    color: #4b505e;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
  }
  
  .blockquote_two {
    background: #f9fafb;
    padding: 20px 20px 20px 40px;
    font-size: 22px;
    font-style: italic;
    color: #627387;
  }
  .blockquote_two img {
    margin-right: 40px;
  }
  
  .blockquote_three {
    background: #f9fafb;
    padding: 40px;
    font-size: 22px;
    color: #677294;
    font-style: italic;
    line-height: 40px;
  }
  .blockquote_three img {
    margin-right: 40px;
  }


  .mt_40 {
    margin-top: 40px;
  }

  .media {
    display: flex;
  }
  .media .media-body {
    flex: 1;
  }



  
  .software_service_area{
    line-height:28px;
    background-color: white;
}

.f_p {
  font-family: "Poppins", sans-serif;
}
.px-md-4{
  padding-left:2rem!important;
  padding-right: 2rem!important;
}

.mb_70 {
  margin-bottom: 70px;
}


.sec_pad {
  padding: 40px 0px;
}

p{
    font-size: 17px;
    color:var(--blog-text-color);
      word-spacing:2px;
  
}

/*************************************************Scroll*/
::-webkit-scrollbar {
  height: 8px;
  padding: 5px;
  width: 1px;
  border :1px solid rgba(138, 132, 132, 0.027);
  scrollbar-width: none;
}
::-webkit-scrollbar-track {
  background-color: rgba(247, 241, 241, 0.712); 
}
::-webkit-scrollbar-thumb {
  background-color: var( --grey-color);
  border-radius: 3px;
}


    /*******************************************Table Of Content*/
.toc-heading{
    color:var(--primary-color); 
  padding: 0 0 10px 0;
}
.toc{
    margin: 0px;
    border: 0px;
    display: block;
  list-style:decimal;
    position: sticky;
    top: 110px;
}
.software_service_tab {
  margin: 0px;
  border: 0px;
  display: block;
   list-style:decimal;
   padding-left: 20px;
   max-height: 400px;
   overflow-y:scroll;
   
}
.software_service_tab .nav-item::marker  {
  color:var(--accent-color);
}

.software_service_tab .nav-item {
  margin: 0px;
}
.software_service_tab .nav-item .nav-link {
  display: block;
  padding: 12px 15px 15px 0;
  transition: border-left-color 0.25s linear;
  border-radius: 0px;
  border: 0px;
  border-right: 2px solid #e6e8ef; 
  background: transparent;
  font: 300 13px "Poppins", sans-serif;
  /* color: var(--light-grey); */
  color:#535353;
  line-height: 20px;
  position: relative;
  cursor: pointer;
 
}

.software_service_tab .nav-item .nav-link.active {
  border-right-color: #3d64f4;
  color: black;
   padding: 12px 5px !important;
  font-weight:600; 
   /* background-color:rgba(var(--primary-color-rgb),.6);  */
   background-color:#ededf9; 
}

.software_service_tab .nav-item .nav-link.active:before {
  opacity: 1;
}

.software_service_tab_content {
  margin-bottom: -70px;
  padding: 10px;
}
.software_service_tab_content .software_service_item {
  padding-right: 30px;
}
.software_service_tab_content .software_service_item i {
  font-size: 30px;
  color: #3d64f4;
  line-height: 40px;
}
.software_service_tab_content .software_service_item h5 {
  font: 600 17px/25px "Poppins", sans-serif;
  color: #222d39;
}
.software_service_tab_content .software_service_item p {
  font-size: 15px;
}



/*************************************Code,links And Highlights****************/
code{
    background-color:#fff3f4;
}

.bg-purpple {
  background: #6e3ef8;
}

 .text-link {
  color: var( --primary-color);
   text-decoration: none;
   outline: none!important;
}
 .text-link:hover{
   text-decoration: underline;
   text-decoration-thickness: 1.5px;
   outline: none;
}




/******************************Headings****************/
.media-and-content p{
  color:grey;
  font-weight: 400;
  font-size: 14px;
  padding:.1rem .2rem .5rem .5rem;
}
.h_title {
  font-size: 24px;
  color: #4b505e;
  font-weight: 300;
  margin-bottom: 50px;
}
.heading_style h1,
.heading_style h2,
.heading_style h3,
.heading_style h4,
.heading_style h5,
.heading_style h6 {
  color:var(--blog-heading-color);
  font-weight: 600;
  padding:.5rem 0 .6rem 0;
}
.heading_style h1 {
  font-size: 50px;
}
.heading_style h2 {
  font-size: 44px;
}
.heading_style h3 {
  font-size: 36px;
}
.heading_style h4 {
  font-size: 30px;
}
.heading_style h5 {
  font-size: 24px;
}
.heading_style h6 {
  font-size: 18px;
}
@media (max-width:575px){
    
}

/*******************************Lists*********/
.nested {
  font-size: 16px;
  color: black;
  line-height: 30px;
  font-weight: 400;
}

.nested ol,.nested ul,.nested ol li,.nested ul li{
  padding-left:10px;
  margin:0;
}
ol {
 list-style:none!important;
 padding-left: 0!important;
}

.nested-links {
  list-style-type: square;
  color: var( --primary-color);
}




/****************************Blockquotes*/
.blockquote {
  border-left: 4px solid var(--primary-color);
  padding: 10px;
  border-radius: 6px;
  padding-left: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
}
.blockquote p {
  font-size: 18px;
  color: var(--blog-text-color);
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
}



/****************************************Subscribe*/
.subscription {
  display: block;
  list-style:decimal;
    position: sticky;
    top: 110px;
  /* position: relative; */
  z-index: 1;
  background: #f9fafd;
  padding: 20px 0px 20px;
  overflow: hidden;
}
.subscription:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../assets/blog-images/map.png") no-repeat scroll center 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.subscription .mchimp-errmessage,
.subscription .mchimp-sucmessage {
  text-align: center;
}
.subscription .privacy-statement{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px;
  color: grey;
}
.right_shape,
.bottom_shape {
  position: absolute;
  width: 700px;
  height: 600px;
}
.right_shape path,
.bottom_shape path {
  opacity: 0.03;
}

.right_shape {
  right: -450px;
  top: -102px;
}

.bottom_shape {
  left: 130px;
  top: 280px;
}
.s_subcribes {
  max-width: 970px;
  margin: 0 auto;
  position: relative;
}
.s_subcribes .form-control {
  font: 300 16px/100px "Poppins", sans-serif;
  color: var(--accent-color);
  height: 60px;
  padding: 0px 0px 0px 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 30px 60px 0px rgba(8, 0, 63, 0.14);
  border: 0px;
  border-radius: 0px;
  border-left-width: 10px;
  border-style: solid;
  z-index: 0;
}
.s_subcribes .form-control.placeholder {
  color: #9ea4b7;
}
.s_subcribes .form-control:-moz-placeholder {
  color: #9ea4b7;
}
.s_subcribes .form-control::-moz-placeholder {
  color: #9ea4b7;
}
.s_subcribes .form-control::-webkit-input-placeholder {
  color: #9ea4b7;
}
.s_subcribes .btn-submit {
  position: absolute;
  background: transparent;
  color:var(--primary-color-1);
  right: 20px;
  line-height: 55px;
  padding: 0px;
  font-size: 20px;
  z-index: 3;
}
.subscribe-heading{
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 10px 0!important;
  color: var(--black-text-color);
}




/*******************************************Author****************/
.media {
  display: flex;
}
.media .media-body {
  flex: 1;
}
.post_author_two {
  background: #f3f5fa;
  border-radius: 4px;
  padding: 35px 40px;
  margin-top: 6rem;
  align-items: center;
}
.post_author_two .img_rounded {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 30px;
}
.post_author_two .media-body .author_info h3 {
  font: 500 18px/24px "Poppins", sans-serif;
  color: #282835;
}
.post_author_two .media-body .author_info .comment-date {
  font: 400 14px "Poppins", sans-serif;
  color: #677294;
}
.post_author_two .media-body p {
  margin-bottom: 0;
  font-size: 16px;
  color: #525c7c;
}

/*****author socials*****/
.post-info-bottom .author_info {
  display: flex;
  justify-content: space-between;
}
.author_info .post-info-bottom .social_icon {
  display: flex;
  text-transform: uppercase;
  font-size: 16px;
  color: #424255;
  font-weight: 500;
  letter-spacing: 1px;
}
.author_info .post-info-bottom .social_icon ul {
  display: inline-block;
}
.author_info .post-info-bottom .social_icon ul li {
  display: inline-block;
  margin-left: 10px;
}
.author_info .post-info-bottom .social_icon ul li a {
  font-size: 12px;
  color: #848ca5;
}
.author_info .post-info-bottom .social_icon ul li a i{
  font-size:1rem;
}
.author_info .post-info-bottom .social_icon ul li a:hover {
  color: #5e2ced;
}


/****************************************related Blog Posts****************/

.blog_related_post {
  margin-top: 100px;
}

.blog_related_post .blog_related_post_heading {
  font-size: 40px;
  color: var(--blog-text-color);
  margin: 0 0 2.5rem 0;
  font-weight: 500;
}

.related-blog-cards{
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.related-blog-cards .related-blog-title{
  font-size: 1.2rem;
  font-weight: 700;
  padding: 10px 0;
  line-height: 30px;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
}

.related-blog-cards p{
  color: rgb(97, 96, 96);
  font-weight: 400;
  margin-bottom: 5px!important;
}
.related-blog-cards .related-blog-info{
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.related-blog-cards .related-blog-info a{
  text-decoration: none;
  color: var(--black-text-color);
  font-size: 1rem;
  font-weight: 600;
}
.related-blog-cards .related-blog-info a:hover{
  text-decoration: none;
  color: var(--accent-color);
  font-size: 1rem;
  font-weight: 600;
}
.related-blog-cards .related-blog-info span{
  color: rgb(119, 118, 118);
  font-size: 1rem;
  font-weight: 600;
  margin-left: 20px;
}





/***********************************************************************Media Queries******************/

@media (max-width:1199px){
    
/*******************************************Table Of Content*/
     .software_service_tab_content .software_service_item{
        padding-right: 0px;
    }
}
@media (max-width:991px){
    .sec_title p br{
        display: none;
    }
    
    
    /*******************************************Table Of Content*/
     .software_service_tab_content .software_service_item{
        margin-bottom: 40px;
    }
    .software_service_tab_content{
        margin-bottom: -40px;
    }
}

@media (max-width:767px){
     .sec_pad,.prototype_service_info{
        padding: 70px 0px;
    }
     .sec_title br,.prototype_service_info h2 br,.hosting_title h2 br{
        display: none;
    }
     .sec_title.mb_70{
        margin-bottom: 50px
    }
    
    
    /*******************************************Table Of Content*/
     .software_service_tab .nav-item{
        display: inline-block;
        margin-bottom: 20px;
       
    }
    .software_service_tab_content{
        margin-top: 60px;
    }

    /*************************related Blogs******/
    .related-blog-cards .related-blog-info{
      flex-direction: column;
    }
    .related-blog-cards .related-blog-info span,.related-blog-cards .related-blog-info a{
      margin-left: 0;
      font-size: .8rem;
    }
    .blog_related_post .blog_related_post_heading {
      font-size: 30px;
    }
    
}

@media (max-width:650px){

  /********************************Author*/
  .post_author_two{
    padding: 25px 20px;
}
}
@media (max-width:575px){
    .software_service_tab_content {
       padding: 20px 0px;
    }

/**************************headings*********/
.software_service_tab_content {
       padding: 10px 0px;
    }
    .heading_style h1 {
      font-size: 40px;
    }
    .heading_style h2 {
      font-size: 34px;
    }
    .heading_style h3 {
      font-size: 24px;
    }
    .heading_style h4 {
      font-size: 20px;
    }
    .heading_style h5 {
      font-size: 18px;
    }
    .heading_style h6 {
      font-size: 16px;
    }
    
    /***************************Para*******/
    p{
        font-size: 14px;
    }
    
    .software_service_area{
        line-height:23px
    }

  /************************************Subscribe*/
  .s_subcribes .form-control{
      line-height: 70px;
      height: 70px;
  }
  .s_subcribes .btn-submit{
      font-size: 18px;
      line-height: 70px;
  }


  /************************************Author*/
  .post_author_two{
    display: block;
  }
  .comment_inner .comment_box .post_comment .reply_comment{
      margin-left: 0;
  }
  .post_author_two .img_rounded{
      margin-right: 0;
      margin-bottom: 20px;
  }
}
